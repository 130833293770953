<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  >
  </dialog-form-list>
</template>

<script>
export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      cmd: this.$store.state.cmd,
      formData: {
        'deviceType': 'WEB',
      },
      config: [
        // lang:文档类型
        {
          tag: 'el-cascader',
          label: this.$t('documentManage.fileType'),
          prop: 'dictId',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        // lang:文档版本号
        {
          label: this.$t('documentManage.fileVersion'),
          prop: 'version',
          rules: {
            noNull: false,
          },
        },
        // lang:文档版上传
        {
          tag: 'document-upload',
          label: this.$t('documentManage.fileUpload'),
          prop: 'fileId',
          rules: {
            noNull: true,
          },
          tagProps: {
            limit: 1,
            accept: '.pdf',
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
    this.findDocument()
  },
  methods: {
    // 查询类型
    findDocument() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType: 'DOCUMENT_TYPE'
        }
      }).then((data) => {
        this.config[0].tagProps.options = data || []
      })
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
        }
      })
    },
    // 文档上传
    documentUploadFileLoad({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileFrom', 'COMPANY_INFO')
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreFileupload,
        data: formData,
        // headers,
        showLoading: false,
      })
        .then((data) => {
          console.log(data)
          this.authorizationFile = data.fileHost + '/' + data.objectName
          this.ruleForm.authorizationFileId = data.id
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      const fileId = formData.fileId.id
      const dictId = formData.dictId[0]
      formData.fileId = fileId
      formData.dictId = dictId
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.addNewDocument,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'add')
      })
    },
  },
}
</script>
